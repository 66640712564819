import React, { useState } from 'react';
import axios from 'axios';

const CreateCategoryForm = ({ onClose, onCreate }) => {
  const [categoryName, setCategoryName] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://api.karthikeshrobotics.in/admin/category', { categoryName }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      onCreate(); // Refresh categories after creation
      onClose(); // Close the form
    } catch (error) {
      console.error('Failed to create category', error);
    }
  };

  return (
    <div className="form-container">
      <h2>Create Category</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Category Name:</label>
          <input
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default CreateCategoryForm;
