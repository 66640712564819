import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdateProductForm = ({ product, onClose, onUpdate, subcategories }) => {
  const [formData, setFormData] = useState(product);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setFormData(product);
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const formDataToSend = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });

      // Append images
      images.forEach((image) => {
        formDataToSend.append('productImages', image);
      });

      await axios.patch(`https://api.karthikeshrobotics.in/admin/products/${formData.productId}`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      onUpdate();
      onClose();
    } catch (error) {
      console.error('Failed to update product', error);
      setError('Failed to update product. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="update-form">
      <h2>Update Product</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          key !== 'categoryId' && key !== 'productId'&& key !== 'productImages'&& key !== 'createdAt'&& key !== 'updatedAt' && key !== 'Subcategory'&& (
            <div key={key}>
              <label>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}:</label>
              <input
                type="text"
                name={key}
                value={formData[key] || ''}
                onChange={handleChange}
              />
            </div>
          )
        ))}
        <div>
          <label>Product Images:</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
        <div>
          <label>Category:</label>
          <select
            name="categoryId"
            value={formData.categoryId || ''}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {subcategories.map(category => (
              <option key={category.sub_categoryId} value={category.sub_categoryId}>
                {category.sub_categoryName}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Update'}
        </button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default UpdateProductForm;
