import React, { useState} from 'react';
import axios from 'axios';

const CreateSubcategoryForm = ({ onClose, onCreate, categories }) => {
  const [subcategoryName, setSubcategoryName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.category_Id || '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://api.karthikeshrobotics.in/admin/subcategory', {
        sub_categoryName: subcategoryName,
        categoryId: selectedCategory
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      onCreate(); // Refresh subcategories after creation
      onClose(); // Close the form
    } catch (error) {
      console.error('Failed to create subcategory', error);
    }
  };

  return (
    <div className="form-container">
      <h2>Create Subcategory</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Subcategory Name:</label>
          <input
            type="text"
            value={subcategoryName}
            onChange={(e) => setSubcategoryName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            {categories.map(category => (
              <option key={category.category_Id} value={category.category_Id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Create</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default CreateSubcategoryForm;
