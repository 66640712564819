import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import CreateProductForm from './CreateProductForm';
import UpdateProductForm from './UpdateProductForm'; // Assuming this is implemented
import CreateCategoryForm from './CreateCategoryForm';
import CreateSubcategoryForm from './CreateSubcategoryForm';
import { fetchData, deleteResource } from './apiOperations';

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showCreateCategoryForm, setShowCreateCategoryForm] = useState(false);
  const [showCreateSubcategoryForm, setShowCreateSubcategoryForm] = useState(false);


  const loadData = async () => {
    try {
      const usersData = await fetchData('/admin/users');
      const categoriesData = await fetchData('/admin/category');
      const subcategoriesData = await fetchData('/admin/subcategory');
      const productsData = await fetchData('/admin/products');

      setUsers(usersData);
      setCategories(categoriesData);
      setSubcategories(subcategoriesData);
      setProducts(productsData);
    } catch (error) {
      console.error('Failed to load data', error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteResource(`/admin/products/${id}`);
      loadData(); // Refresh data after delete
    } catch (error) {
      console.error(`Failed to delete product with ID ${id}`, error);
    }
  };

  const handleUpdate = (product) => {
    setSelectedProduct(product);
    setShowUpdateForm(true);
  };

  const handleCreateFormOpen = () => {
    setShowCreateForm(true);
  };
  
  const handleCreateCategoryFormOpen = () => {
    setShowCreateCategoryForm(true);
  };

  const handleCreateSubcategoryFormOpen = () => {
    setShowCreateSubcategoryForm(true);
  };

  const handleFormClose = () => {
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowCreateCategoryForm(false);
    setShowCreateSubcategoryForm(false);
  };

  const handleUpdateComplete = () => {
    loadData(); // Refresh data after update
  };


  return (
    <div>
      <h2>Dashboard</h2>
      <div>
        <h3>Users</h3>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Gender</th>
              <th>Role</th>
              <th>Profile Pic</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.user_Id}>
                <td>{user.user_Id}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.gender}</td>
                <td>{user.role}</td>
                <td>
                  {user.profile_pic ? (
                    <img
                      src={`https://api.karthikeshrobotics.in/images/profile/${user.profile_pic}`} // Correct URL
                      alt={`${user.profile_pic}`}
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  ) : (
                    'No Image'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h3>Categories</h3>
        <button onClick={handleCreateCategoryFormOpen}>Add Category</button>
       
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <tr key={category.category_Id}>
                <td>{category.category_Id}</td>
                <td>{category.categoryName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h3>Subcategories</h3>
        <button onClick={handleCreateSubcategoryFormOpen}>Add Subcategory</button>
      
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Category ID</th>
              <th>Category Name</th>
            </tr>
          </thead>
          <tbody>
            {subcategories.map(subcategory => (
              <tr key={subcategory.sub_categoryId}>
                <td>{subcategory.sub_categoryId}</td>
                <td>{subcategory.sub_categoryName}</td>
                <td>{subcategory.categoryId}</td>
                {categories.map(category =>
                  category.category_Id === subcategory.categoryId ? (
                    <td key={category.category_Id}>{category.categoryName}</td>
                  ) : null
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h3>Products</h3>
        <button onClick={handleCreateFormOpen}>Add Product</button>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Brand</th>
              <th>Color</th>
              <th>Name</th>
              <th>Price</th>
              <th>Discount Price</th>
              <th>Description</th>
              <th>Stock Quantity</th>
              <th>Category ID</th>
              <th>Category Name</th>
              <th>Images</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.productId}>
                <td>{product.productId}</td>
                <td>{product.productBrand}</td>
                <td>{product.productColor}</td>
                <td>{product.productName}</td>
                <td>{product.productPrice}</td>
                <td>{product.productDiscontPrice}</td>
                <td>{product.productDescription}</td>
                <td>{product.productStockQuantity}</td>
                <td>{product.categoryId}</td>
                {subcategories.map(subcategory =>
                  product.categoryId === subcategory.sub_categoryId ? (
                    <td key={subcategory.sub_categoryId}>{subcategory.sub_categoryName}</td>
                  ) : null
                )}
                <td>
                  {product.productImages && Array.isArray(product.productImages) ? (
                    product.productImages.map((image, index) => (
                      <img
                        key={index}
                        src={`https://api.karthikeshrobotics.in/images/products/${image.filename}`}
                        alt={`Product img ${index + 1}`}
                        style={{ width: '100px', height: 'auto', margin: '5px' }} // Adjust as needed
                      />
                    ))
                  ) : (
                    <span>No images available</span>
                  )}
                </td>
                <td>     
                  <button onClick={() => handleUpdate(product)}>Update</button>
                  <button onClick={() => handleDelete(product.productId)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Conditionally render the forms */}
      {showCreateForm && <CreateProductForm onClose={handleFormClose} onCreate={loadData} subcategories={subcategories} />}
      {showUpdateForm && <UpdateProductForm product={selectedProduct} onClose={handleFormClose} onUpdate={handleUpdateComplete} subcategories={subcategories} />}
      {showCreateCategoryForm && <CreateCategoryForm onClose={handleFormClose} onCreate={loadData} />}
      {showCreateSubcategoryForm && <CreateSubcategoryForm onClose={handleFormClose} onCreate={loadData} categories={categories} />}
     </div>    
  );
};

export default Dashboard;
