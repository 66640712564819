import React, { useState } from 'react';
import axios from 'axios';

const CreateProductForm = ({ onClose, onCreate, subcategories }) => {
  const [product, setProduct] = useState({
    productBrand: '',
    productColor: '',
    productName: '',
    productPrice: '',
    productDiscontPrice: '',
    productDescription: '',
    productStockQuantity: '',
    categoryId: ''
  });

  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');

      // Prepare form data
      const formData = new FormData();
      formData.append('productBrand', product.productBrand);
      formData.append('productColor', product.productColor);
      formData.append('productName', product.productName);
      formData.append('productPrice', product.productPrice);
      formData.append('productDiscontPrice', product.productDiscontPrice);
      formData.append('productDescription', product.productDescription);
      formData.append('productStockQuantity', product.productStockQuantity);
      formData.append('categoryId', product.categoryId);

      // Append images
      images.forEach((image, index) => {
        formData.append('productImages', image);
      });

      await axios.post('https://api.karthikeshrobotics.in/admin/products', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data' // Important for file uploads
        }
      });
      onCreate();
      onClose();
    } catch (error) {
      console.error('Failed to create product', error);
    }
  };

  return (
    <div className="create-form">
      <h2>Create Product</h2>
      <form onSubmit={handleSubmit}>
        {Object.keys(product).map((key) => (
          key !== 'categoryId' && (
            <div key={key}>
              <label>{key}:</label>
              <input
                type="text"
                name={key}
                value={product[key] || ''}
                onChange={handleChange}
              />
            </div>
            
          )
        ))}
          <div>
          <label>Product Images:</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
        <div>
          <label>Category:</label>
          <select
            name="categoryId"
            value={product.categoryId || ''}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {subcategories.map(category => (
              <option key={category.sub_categoryId} value={category.sub_categoryId}>
                {category.sub_categoryName}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Create</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default CreateProductForm;
